<template>
  <div>
    <div class="header_common_box">
        <v-header :params="{ title, leftIcon: true }"></v-header>
    </div>
    <div>
        <van-tabs v-model="active" @click="onClickTab">
            <van-tab title="排列五">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
                >
                <div v-for="(item,index) in list" :key="index" class="item" @click="gotoDetail(item.hongrenId)">
                    <p><span class="title">{{ item.hongrenTitle }}</span><span class="author">{{ item.author }}</span></p>
                    <!-- <img :src="item.headImgUrl"/> -->
                    <van-image
                        width="9.6rem"
                        height="7rem"
                        fit="fill"
                        :src="item.headImgUrl"
                        style="padding:0.1rem;"
                    />
                    <hr/>
                </div>
                </van-list>
            </van-tab>
            <van-tab title="七星彩">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
                >
                <div v-for="(item,index) in list" :key="index" class="item" @click="gotoDetail(item.hongrenId)">
                    <p><span class="title">{{ item.hongrenTitle }}</span><span class="author">{{ item.author }}</span></p>
                    <!-- <img :src="item.headImgUrl"/> -->
                    <van-image
                        width="9.6rem"
                        height="7rem"
                        fit="fill"
                        :src="item.headImgUrl"
                        style="padding:0.1rem;"
                    />
                    <hr/>
                </div>
                </van-list>
            </van-tab>
            
        </van-tabs>

    </div>
  </div>
</template>

<script>
import vHeader from "@/components/header.vue";
export default {
    components:{
        vHeader
    },
    data(){
        return{
            title:this.$route.meta.title,
            active:0,
            list:[],
            pagination: {
                pageNo: 1,
                pageSize: 10,
            },
            loading: false,
            finished: false,
        }
    },
    methods:{
        async onLoad(){
            // console.log('finished,loading:',this.finished,this.loading)

            let data = {
                lotteryKind: this.active === 0 ? 5 : 7,
                pageNo: this.pagination.pageNo,
                pageSize: this.pagination.pageSize,
                // sortBy: 'userId DESC',
                systemId: 100001, 
                }
                const res = await this.$HTTP.post(this, 'API_FAMOUS_LIST', data, { headers: { access_token: this.$store.state.common.loginInfo.access_token }}, true)
               
                this.loading = false
                if (res.statusCode === 200) {
                    // console.log('红人榜信息：',res)
                    this.pagination.pageNo += 1
                    this.list=this.list.concat(res.data)
                    
                }
                if (this.list.length >= res.totalSize * 1) {
                        this.finished = true;
                }
                // console.log('list:',this.list)
        },
        async onClickTab(){
            this.loading=false
            this.finished=false
            
            this.pagination={
                pageNo: 1,
                pageSize: 10,
            }
            this.list=[]
            console.log(this.active===0?5:7)
        },
        gotoDetail(id){
            this.$router.push({
                name: 'famousdetail',
                query:{
                    id: id,
                }
            })
        },
    },
    mounted(){
    }
}
</script>

<style lang="less" scoped>
*{
    margin: 0;
    padding: 0;
}
.item{
    background-color: white;
    p{
        font-size: 0.5rem;
        font-weight: 600;
        text-align: center;
        padding: 0.2rem 0;
        .title{
            margin: 0.1rem 1rem;
        }
        .author{
            margin: 0.1rem 1rem;

        }
        
    }
    img{
        width: 10rem;
        height: 10rem;
        padding: 0.2rem;
    }
}
</style>